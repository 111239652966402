// This file is for other site-wide variables that are not used by:
// '/node_modules/bootstrap/scss/variables';

// OMGUI Variables
// These are variables used by our OMGUI Components

@import 'colors';

// Card Radius
$omgui-card-radius: calc(0.75rem - 1px) !default; /* subtract 1px to account for border width */

// Button Radius
$omgui-button-radius: 0.5rem !default;

// Other Variables
// Datepicker
$ngb-dp-font-size: $font-size-sm !default;
$ngb-dp-bg-color: $white !default;
$ngb-dp-selected-bg-color: rgba(27, 126, 117, 0.5) !default;
$ngb-dp-hover-bg-color: $primary !default;

$ngb-dp-disabled-font-color: $brand-gray-light !default;
$ngb-dp-font-color: $brand-gray-dark !default;
$ngb-dp-contrast-font-color: $white !default;

// Profile Bubble
$extra-small-size: 30px;
$small-size: 40px;
$medium-small-size: 48px;
$medium-size: 90px;
$large-size: 125px;
$extra-large-size: 175px;
$extra-extra-large-size: 200px;

$profile-bubble-size-map: (
  'extra-small': $extra-small-size,
  'small': $small-size,
  'medium-small': $medium-small-size,
  'medium': $medium-size,
  'large': $large-size,
  'extra-large': $extra-large-size,
  'extra-extra-large': $extra-extra-large-size,
);
