$custom-control-gutter: 1.5rem !default;

$custom-control-indicator-bg: $gray-300 !default;
$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;

$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;
$custom-control-indicator-checked-box-shadow: null !default;
$custom-control-indicator-focus-border-color: $input-focus-border-color !default;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg !default;
$custom-control-label-disabled-color: $gray-600 !default;
$custom-control-indicator-disabled-bg: $input-disabled-bg !default;
$custom-control-label-color: null !default;
$custom-control-cursor: null !default;
$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-indicator-border-width: $input-border-width !default;
$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;
$custom-control-indicator-checked-disabled-bg: rgba($primary, 0.5) !default;
$custom-switch-width: $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius: $custom-control-indicator-size * 0.5 !default;
$custom-switch-indicator-size: subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4) !default;
$custom-forms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// https://useiconic.com/open

// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: $font-size-base * $line-height-base;
  padding-left: $custom-control-gutter + $custom-control-indicator-size;
  print-color-adjust: exact; // Keep themed appearance for print
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  width: $custom-control-indicator-size;
  height: ($font-size-base * $line-height-base + $custom-control-indicator-size) * 0.5;
  opacity: 0;

  &:checked ~ .custom-control-label::before {
    color: $custom-control-indicator-checked-color;
    border-color: $custom-control-indicator-checked-border-color;
    @include gradient-bg($custom-control-indicator-checked-bg);
    @include box-shadow($custom-control-indicator-checked-box-shadow);
  }

  &:focus ~ .custom-control-label::before {
    // the mixin is not used here to make sure there is feedback
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $custom-control-indicator-focus-box-shadow;
    } @else {
      box-shadow: $custom-control-indicator-focus-box-shadow;
    }
  }

  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $custom-control-indicator-focus-border-color;
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    color: $custom-control-indicator-active-color;
    background-color: $custom-control-indicator-active-bg;
    border-color: $custom-control-indicator-active-border-color;
    @include box-shadow($custom-control-indicator-active-box-shadow);
  }

  // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .custom-control-label {
      color: $custom-control-label-disabled-color;

      &::before {
        background-color: $custom-control-indicator-disabled-bg;
      }
    }
  }
}

// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  color: $custom-control-label-color;
  vertical-align: top;
  cursor: $custom-control-cursor;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) * 0.5;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: '';
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
    @include box-shadow($custom-control-indicator-box-shadow);
  }

  // Foreground (icon)
  &::after {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) * 0.5;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    content: '';
    background: 50% / #{$custom-control-indicator-bg-size} no-repeat;
  }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
  .custom-control-label::before {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: $custom-radio-indicator-border-radius;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: escape-svg($custom-radio-indicator-icon-checked);
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }
  }
}

// switches
//
// Tweak a few things for switches

.custom-switch {
  padding-left: $custom-switch-width + $custom-control-gutter;

  .custom-control-label {
    &::before {
      left: -($custom-switch-width + $custom-control-gutter);
      width: $custom-switch-width;
      pointer-events: all;
      // stylelint-disable-next-line property-disallowed-list
      border-radius: $custom-switch-indicator-border-radius;
    }

    &::after {
      top: add(($font-size-base * $line-height-base - $custom-control-indicator-size) * 0.5, $custom-control-indicator-border-width * 2);
      left: add(-($custom-switch-width + $custom-control-gutter), $custom-control-indicator-border-width * 2);
      width: $custom-switch-indicator-size;
      height: $custom-switch-indicator-size;
      background-color: $custom-control-indicator-border-color;
      // stylelint-disable-next-line property-disallowed-list
      border-radius: $custom-switch-indicator-border-radius;
      @include transition(transform 0.15s ease-in-out, $custom-forms-transition);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: $custom-control-indicator-bg;
      transform: translateX($custom-switch-width - $custom-control-indicator-size);
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }
  }
}

.custom-control-label::before {
  @include transition($custom-forms-transition);
}
