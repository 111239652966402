@import 'theme';

.bb-grid line {
  stroke: $brand-gray-light;
}

.bb-xgrid,
.bb-ygrid {
  stroke-dasharray: 0;
}

.bb-tooltip th {
  background-color: $brand-gray-sand;
}
