//Bootstrap 5 adds a new xxl breakpoint and makes other small changes that modifies our page in unexpected ways.
//These changes revert some Bootstrap values to v4 defaults
//This file should be deleted once design has fully adapted the site's design to Bootstrap 5.

// scss-docs-start container-max-widths
$container-max-widths: (
  // same in Bootstrap 5
  sm: 540px,
  // same in Bootstrap 5
  md: 720px,
  // same in Bootstrap 5
  lg: 960px,
  // same in Bootstrap 5
  xl: 1140px,
  // xxl: 1400px Disabling because we don't currently support it
) !default;

$grid-gutter-width: 30px !default; // 1.5rem !default; in Bootstrap 5

$enable-rfs: false; // true; in Bootstrap 5. Talked to Tony about it on Jul 1, 2022. Not using responsive font sizing. He prefers having control of the responsiveness.

$link-decoration: none !default;
$link-hover-decoration: underline !default;

$card-cap-padding-y: 0.75rem !default; // $card-spacer-y * .5 !default; in Bootstrap 5
