// Bootstrap 5 drops `.close` and adds `.btn-close`. Though the 2 serve the same purpose, they have different styling
// so the entire Bootstrap 4 file was copied to here and modified as needed

//copied from _variables.scss
$close-color: $black !default;
$close-text-shadow: 0 1px 0 $white !default;

.close {
  float: right;
  font-size: 40px; // original Bootstrap 4 value - @include font-size($close-font-size);
  font-weight: 100; // original Bootstrap 4 value - font-weight: $close-font-weight;
  line-height: 20px; // original Bootstrap 4 value - line-height: 1;
  color: $close-color;
  text-shadow: $close-text-shadow;
  opacity: 0.5;
  font-family: -webkit-pictograph; // our setting. Not from Bootstrap 4

  // Override <a>'s hover style
  &:hover {
    color: $close-color;
    text-decoration: none;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

// stylelint-disable-next-line selector-no-qualifying-type
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

// Future-proof disabling of clicks on `<a>` elements

// stylelint-disable-next-line selector-no-qualifying-type
a.close.disabled {
  pointer-events: none;
}
