@import 'src/app/omgui/bootstrap-4/mixins/buttons';

.btn:disabled {
  pointer-events: auto; // Bootstrap 5 sets it to none.
}

/* Per Bootstrap 5 migration doc (https://getbootstrap.com/docs/5.0/migration/#buttons):
Dropped .btn-block for utilities. Instead of using .btn-block on the .btn, wrap your buttons with .d-grid and a .gap-*
utility to space them as needed. Switch to responsive classes for even more control over them.
Read the docs (https://getbootstrap.com/docs/5.0/components/buttons/#block-buttons) for some examples.
*/

$btn-block-spacing-y: 0.5rem;

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}
