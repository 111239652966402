@import 'theme';

ngb-datepicker.om-datepicker {
  font-size: $ngb-dp-font-size;

  .range {
    &.range-start.rounded {
      border-radius: 0.25rem 0 0 0.25rem !important;
    }

    &.range-end.rounded {
      border-radius: 0 0.25rem 0.25rem 0 !important;
    }

    &.faded.rounded {
      border-radius: unset !important;
    }
  }

  .selected {
    background-color: $ngb-dp-selected-bg-color;
    color: $white;
  }

  .custom-day {
    text-align: center;
    display: inline-block;
    height: 33px;
    width: 41px;
  }

  .custom-day:hover {
    background-color: $primary;
    color: $ngb-dp-contrast-font-color;
  }

  .custom-day.faded {
    background-color: $ngb-dp-hover-bg-color;
  }

  .custom-day.disabled {
    color: $ngb-dp-disabled-font-color;
    background-color: $ngb-dp-bg-color;
  }

  .ngb-dp-weekday {
    color: $ngb-dp-font-color;
    font-weight: bold;

    &.font-italic {
      font-style: normal !important;
    }
  }

  .ngb-dp-day,
  .ngb-dp-weekday {
    text-align: center;
    width: 41px;
    height: 33px;
  }

  .ngb-dp-week.bg-light,
  .ngb-dp-header.bg-light {
    background-color: $ngb-dp-bg-color !important;
  }

  .ngb-dp-weekdays {
    border: none;
  }

  .ngb-dp-week,
  .ngb-dp-weekdays {
    justify-content: center;
  }

  .ngb-dp-month-name {
    font-weight: bolder;
  }

  .ngb-dp-month {
    width: 100%;
  }

  .ngb-dp-navigation-chevron {
    font-size: 14px;
    color: $brand-gray-dark;
  }
}
