//== Type System Redesign Fonts
//
@font-face {
  font-family: 'One GT Super Display';
  src: url('/assets/fonts/gt-super/gt-super-display-regular.woff2') format('woff2'), url('/assets/fonts/gt-super/gt-super-display-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'One GT Super Display';
  src: url('/assets/fonts/gt-super/gt-super-display-regular-italic.woff2') format('woff2'), url('/assets/fonts/gt-super/gt-super-display-regular-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'One GT Super Display';
  src: url('/assets/fonts/gt-super/gt-super-display-medium.woff2') format('woff2'), url('/assets/fonts/gt-super/gt-super-display-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'One GT Super Display';
  src: url('/assets/fonts/gt-super/gt-super-display-medium-italic.woff2') format('woff2'), url('/assets/fonts/gt-super/gt-super-display-medium-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'One GT Super Display';
  src: url('/assets/fonts/gt-super/gt-super-display-bold.woff2') format('woff2'), url('/assets/fonts/gt-super/gt-super-display-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'One GT Super Display';
  src: url('/assets/fonts/gt-super/gt-super-display-bold-italic.woff2') format('woff2'), url('/assets/fonts/gt-super/gt-super-display-bold-italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-thin.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-thin-italic.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-thin-italic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-light.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-light-italic.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-light-italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-regular.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-regular-italic.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-regular-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-medium.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-medium-italic.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-medium-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-medium.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-medium-italic.woff2') format('woff2'), url('/assets/fonts/ginto/ginto-normal-medium-italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Ginto';
  src: url('/assets/fonts/ginto/ginto-normal-bold-italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

//== Old Fonts
//
@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/sofiapro-ultralight.woff2') format('woff2'), url('/assets/fonts/sofiapro-ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/sofiapro-light.woff2') format('woff2'), url('/assets/fonts/sofiapro-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/sofiapro-light-italic.woff2') format('woff2'), url('/assets/fonts/sofiapro-light-italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/sofiapro-regular.woff2') format('woff2'), url('/assets/fonts/sofiapro-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/sofiapro-regular-italic.woff2') format('woff2'), url('/assets/fonts/sofiapro-regular-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/sofiapro-semibold.woff2') format('woff2'), url('/assets/fonts/sofiapro-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/sofiapro-semibold-italic.woff2') format('woff2'), url('/assets/fonts/sofiapro-semibold-italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/sofiapro-bold.woff2') format('woff2'), url('/assets/fonts/sofiapro-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/sofiapro-bold-italic.woff2') format('woff2'), url('/assets/fonts/sofiapro-bold-italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
