@import '/node_modules/bootstrap/scss/variables';
@import '/node_modules/bootstrap/scss/mixins';

// Responsive font-sizes for mobile screen sizes.
// Consider replacing this with Bootstrap's responsive font sizing
// https://getbootstrap.com/docs/4.6/content/typography/#responsive-font-sizes
// Talked to Tony about it on Jul 1, 2022. Not using responsive font sizing. He prefers having control of the responsiveness.
// $enable-responsive-font-sizes: true;
@include media-breakpoint-down(md) {
  .display-5 {
    font-size: $font-size-base * 2;
  }

  h1,
  .h1 {
    font-size: $h1-font-size-sm;
  }

  h2,
  .h2 {
    font-size: $h2-font-size-sm;
  }

  h3,
  .h3 {
    font-size: $h3-font-size-sm;
  }

  h4,
  .h4 {
    font-size: $h4-font-size-sm;
  }

  h5,
  .h5 {
    font-size: $h5-font-size-sm;
  }

  h6,
  .h6 {
    font-size: $h6-font-size-sm;
  }

  .lead {
    font-size: $font-size-base;
  }

  p,
  .p {
    font-size: $p-font-size-sm;
  }

  ul,
  .ul,
  ol,
  .ol {
    font-size: $ul-font-size-sm;
  }
}
