/*
    Keyframes have to be defined at the root. Sass will pull out nested keyframe definitions to the root.
    Define all keyframes here so that we don't end up with any naming conflicts.

    If you are adding a new animation, please consider Angular animations instead of CSS.
*/

@keyframes clockwise-rotation {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes counter-clockwise-rotation {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes extra-extra-small-dash {
  0% {
    stroke-dashoffset: 162;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes extra-small-dash {
  0% {
    stroke-dashoffset: 264;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes small-dash {
  0% {
    stroke-dashoffset: 326;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes medium-small-dash {
  0% {
    stroke-dashoffset: 390;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes medium-dash {
  0% {
    stroke-dashoffset: 640;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes large-dash {
  0% {
    stroke-dashoffset: 860;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes extra-large-dash {
  0% {
    stroke-dashoffset: 1174;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes thick-and-thin {
  from {
    animation-timing-function: ease-out;
  }

  63% {
    animation-timing-function: ease-in-out;
    stroke-width: 5;
  }

  89% {
    stroke-width: 3;
  }
}

@keyframes pulsating {
  63% {
    transform: scale(1.1);
    animation-timing-function: ease-in-out;
  }

  89% {
    transform: scale(1);
  }
}

@keyframes logo-bubble-shift-right {
  from {
    left: calc(50% - 100px);
  }
  to {
    left: calc(50%);
  }
}

@keyframes logo-bubble-shift-left {
  from {
    right: calc(50% - 90px);
  }
  to {
    right: calc(50%);
  }
}
